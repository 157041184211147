// Loading Vue
import { createApp } from "vue"
import { createPinia } from "pinia"
import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from "./stores/AuthStore"

import Dashboard from "./vue/Dashboard.vue"
import DashboardPageHome from "./vue/DashboardPageHome.vue"
import DashboardPageCommercialAnalysis from "./vue/DashboardPageCommercialAnalysis.vue"
import DashboardPageCommercialManagement from "./vue/DashboardPageCommercialManagement.vue"
import DashboardPageProperty from "./vue/DashboardPageProperty.vue"

import Routing from 'fos-router'

const pinia = createPinia()
const dashboard = createApp(Dashboard)

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: Routing.generate('dashboard_home'), component: DashboardPageHome },
        { path: Routing.generate('dashboard_commercial_analysis'), component: DashboardPageCommercialAnalysis },
        { path: Routing.generate('dashboard_commercial_management'), component: DashboardPageCommercialManagement },
        { path: Routing.generate('dashboard_property'), component: DashboardPageProperty },
    ],
})

dashboard
    .use(pinia)
    .use(router) // router should have its own "use" call

dashboard.mount('#dashboard-app')

// initializing the auth store with the currently logged user
const authStore = useAuthStore()
authStore.getLoggedUser()

