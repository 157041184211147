<template>
  <div class="tile indicator number-evolution" :class="{'flat': history.length === 0}">
    <div v-if="loading" class="loader">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-else class="content">
      <div class="legend">{{ legendString }}</div>
      <div class="values d-flex flex-row align-items-center">
        <div v-if="currency" class="align-self-start currency">{{ currency }}</div>
        <div class="fw-bold text-dark value">{{ shortenValue(value) }}</div>
        <div v-if="evolutionValue" :class="evolutionSpanClasses" data-bs-toggle="tooltip" :title="shortenValue(previous)">
          <i v-if="evolutionValue >= 0" class="fas fa-arrow-up"></i>
          <i v-else class="fas fa-arrow-down"></i>
          {{ evolutionValue }}%
        </div>
      </div>
      <bar v-if="history.length > 2"
           id="my-chart-id"
           :options="chartOptions"
           :data="chartData"
      ></bar>
    </div>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs'

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import $ from "jquery";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "IndicatorNumberEvolution",

  components: {
    Bar
  },

  props: {
    value: {
      type: Number,
      default: 0.0,
    },
    previous: {
      type: Number,
      default: null,
    },
    showEvolution: {
      type: Boolean,
      default: true,
    },
    history: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: null,
    },
    legend: {
      type: String,
      default: "",
    },
    chartColor: {
      type: String,
      default: "#007bff",
    },
    formatValue: {
      type: Boolean,
      default: true,
    },
    decimals: {
      type: Number,
      default: 2,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    calculationType: {
      type: String,
      default: null,
    },
  },

  mounted() {
    $('[data-bs-toggle="tooltip"]').tooltip()
  },

  computed: {
    /**
     * Return the value evolution as a percentage
     * @returns {null}
     */
    evolutionValue() {
      if (this.showEvolution && this.previous) {
        return (((this.value - this.previous) / this.previous) * 100).toFixed(2)
      }
      return null
    },

    /**
     * Returns the full legend string
     * @returns {string}
     */
    legendString() {
      let legendString = this.legend
      if (this.calculationType === 'number') {
        legendString += " (en nombre)"
      } else if (this.calculationType === 'turnover') {
        legendString += " (en CA)"
      }
      return legendString
    },

    /**
     * Returns the classes for the value evolution badge
     * @returns {string}
     */
    evolutionSpanClasses() {
      if (this.evolutionValue >= 0) {
        return "badge bg-success"
      } else {
        return "badge bg-danger"
      }
    },

    chartData() {
      return {
        labels: this.history.map((value) => value.label),
        datasets: [ { data: this.history.map((value) => value.total) } ]
      }
    },

    chartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          }
        },
        scales: {
          x: {
            display: false,
            grid: {
              display: false
            }
          },
          y: {
            display: false,
            grid: {
              display: false
            }
          }
        },
        elements: {
          bar: {
            backgroundColor: this.chartColor,
            borderRadius: 50,
          }
        },
      }
    },
  },

  methods: {
    shortenValue(value) {
      if (!this.formatValue) {
        if (this.decimals) {
          return new Intl.NumberFormat('fr-FR', { style: 'decimal', useGrouping: 'auto' }).format(value.toFixed(this.decimals))
        }
        return value
      }

      if (value > 1000000) {
        return new Intl.NumberFormat('fr-FR', { style: 'decimal', useGrouping: 'auto' }).format((value / 1000000).toFixed(this.decimals)) + "M"
      }
      if (value > 10000) {
        return new Intl.NumberFormat('fr-FR', { style: 'decimal', useGrouping: 'auto' }).format((value / 1000).toFixed(this.decimals)) + "k"
      }
      return new Intl.NumberFormat('fr-FR', { style: 'decimal', useGrouping: 'auto' }).format(value.toFixed(this.decimals))
    }
  },
}
</script>