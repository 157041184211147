<template>
  <div id="dashboard-commercial-management-page">
    <div class="row">
      <div class="col-5 col-xs-12">
        <div class="row">
          <div class="col-6 indicator-container">
            <indicator-helper indicator="global_turnover"></indicator-helper>
            <indicator-number-evolution
                v-if="indicators.turnover"
                :value="indicators.turnover.current"
                :previous="indicators.turnover.previous"
                :history="indicators.turnover.history"
                :loading="loading"
                legend="Chiffre d'affaires"
                chart-color="lightskyblue"
                currency="€"
            ></indicator-number-evolution>
          </div>
          <div class="col-6 indicator-container">
            <indicator-helper indicator="mean_average_basket"></indicator-helper>
            <indicator-number-evolution
                v-if="indicators.average_basket"
                :value="indicators.average_basket.current"
                :previous="indicators.average_basket.previous"
                :history="indicators.average_basket.history"
                :loading="loading"
                legend="Panier moyen signé"
                chart-color="yellow"
                currency="€"
            ></indicator-number-evolution>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="tile">
              <h3>Données par type de BDC</h3>
              <table class="table table-striped table-objectives">
                <thead>
                  <tr>
                    <th scope="col">Type de BDC</th>
                    <th scope="col" class="text-right">CA</th>
                    <th scope="col" class="text-right">% CA</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!loading" v-for="data in indicators.turnover_by_type.current">
                    <td>{{ data.type }}</td>
                    <td class="text-right">{{ formatCurrency(data.total) }}</td>
                    <td class="text-right">{{ formatPercentage(data.percent) }}</td>
                  </tr>
                  <tr v-else>
                    <td colspan="2">Aucune donnée</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-7 col-xs-12">
        <div class="tile" v-if="indicators.objectives">
          <h3>Données par commercial</h3>
          <div v-if="!authIsAdmin" class="alert alert-warning">
            <i class="fa-solid fa-exclamation-triangle"></i>
            Vous ne visualisez pas la totalité des utilisateurs car vous n'êtes pas administrateur.
          </div>
          <div class="form-check mt-3">
            <input class="form-check-input" type="checkbox" v-model="hideEmptySalesmenRows" id="hideEmptySalesmenRows">
            <label class="form-check-label" for="hideEmptySalesmenRows">
              Cacher les lignes vides
            </label>
          </div>
          <div class="alert alert-info mt-3">
            <i class="fa-solid fa-info-circle"></i>
            Les données ci-dessous ne concernent que la période sélectionnée dans le menu
          </div>
          <table v-for="(drData, drName) in indicators.objectives" class="table table-striped table-objectives mb-5">
            <thead>
              <tr>
                <th class="col-30 dr-th">{{ drName ? drName : '(Sans DR)' }}</th>
                <th>Fonction</th>
                <th class="text-right">Objectif</th>
                <th class="text-right">CA Cumulé</th>
                <th class="text-center">R/O</th>
                <th class="text-center">Taux ren.</th>
                <th class="text-center">Taux remise</th>
                <th class="text-center">Taux conv.</th>
                <th class="text-center">Panier moy.</th>
                <th class="text-center">Délai sign.</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="salesman in filterDrSalesmen(drData)">
                <td :data-egiteko-id="salesman.egiteko_id">{{ salesman.user.last_name }} {{ salesman.user.first_name }}</td>
                <td>{{ salesman.user.is_manager ? 'Manager' : 'Commercial' }}</td>
                <td class="text-right">{{ formatCurrency(salesman.objective) }}</td>
                <td class="text-right">{{ formatCurrency(salesman.turnover) }}</td>
                <td class="text-center">{{ getCommercialRO(salesman.objective, salesman.turnover) }}</td>
                <td class="text-center">{{ getRenewal(salesman.renewal) }}</td>
                <td class="text-center">{{ formatPercentage(salesman.discount_rate) }}</td>
                <td class="text-center">{{ formatPercentage(salesman.conversion_rate) }}</td>
                <td class="text-center">{{ formatCurrency(salesman.average_basket) }}</td>
                <td class="text-center">{{ formatSignatureDelay(salesman.signature_delay) }}</td>
              </tr>
              <tr class="fw-bold">
                <td colspan="2">Total</td>
                <td class="text-right">{{ formatCurrency(calculateDrTotalObjective(drData)) }}</td>
                <td class="text-right">{{ formatCurrency(calculateDrTotalTurnover(drData)) }}</td>
                <td class="text-center">{{ getCommercialRO(calculateDrTotalObjective(drData), calculateDrTotalTurnover(drData)) }}</td>
                <td class="text-center">{{ formatPercentage(calculateDrMeanRenewalRate(drData)) }}</td>
                <td class="text-center">{{ formatPercentage(calculateDrMeanDiscountRate(drData)) }}</td>
                <td class="text-center">{{ formatPercentage(calculateDrMeanConversionRate(drData)) }}</td>
                <td class="text-center">{{ formatCurrency(calculateDrMeanAverageBasket(drData)) }}</td>
                <td class="text-center">{{ formatSignatureDelay(calculateDrMeanSignatureDelay(drData)) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IndicatorNumberEvolution from "./IndicatorNumberEvolution.vue"
import Routing from 'fos-router'
import IndicatorHelper from "./IndicatorHelper.vue"
import { useAuthStore } from "../stores/AuthStore"
import {mapState} from "pinia";

export default {
  name: "DashboardPageCommercialManagement",

  components: {
    IndicatorHelper,
    IndicatorNumberEvolution,
  },

  props: {
    startDate: String,
    endDate: String,
    rateCalculationType: String,
  },

  data() {
    return {
      hideEmptySalesmenRows: false,
      indicators: {},
      loading: true,
    };
  },

  mounted() {
    this.fetchAll()
  },

  watch: {
    startDate() {
      this.fetchAll()
    },
    endDate() {
      this.fetchAll()
    },
    rateCalculationType() {
      this.fetchAll()
    }
  },

  computed: {
    // importing information from auth store
    ...mapState(useAuthStore, ['authIsAdmin']),
  },

  methods: {
    /**
     * Method called to refresh all home indicators
     */
    fetchAll() {
      this.loading = true
      axios.get(Routing.generate('api_dashboard_commercial_management', {
        dashboard_start_date: this.startDate,
        dashboard_end_date: this.endDate,
        rate_calculation_type: this.rateCalculationType,
      }))
        .then(response => {
          this.indicators = response.data.results
          this.loading = false
        })
        .catch(error => {
          console.error(error)
        })
    },

    formatCurrency(amount, currency = 'EUR') {
      if (amount === null) {
        return '-'
      }
      return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: currency }).format(amount)
    },

    formatPercentage(percentage, digits = 2) {
      if (percentage === null) {
        return '-'
      } 
      return new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: digits, maximumFractionDigits: digits }).format(percentage)
    },

    formatSignatureDelay(signature_delay, digits = 1) {
      if (signature_delay === null) {
        return '-'
      } 
      return new Intl.NumberFormat('fr-FR', { minimumFractionDigits: digits, maximumFractionDigits: digits }).format(signature_delay) + ' j'
    },

    /**
     * Check the commercial turnovers to get the one for the given commercial Egiteko ID
     *
     * @param commercialEgitekoId
     * @returns {number|*}
     */
    getCommercialRealizedTurnover(commercialEgitekoId) {
      if (this.indicators.objectives.realized[parseInt(commercialEgitekoId)]) {
        return this.indicators.objectives.realized[parseInt(commercialEgitekoId)].total
      }
      return 0.0
    },

    /**
     * Calculate the R/0
     *
     * @param objective
     * @param realized
     * @returns {number|null}
     */
    getCommercialRO(objective, realized) {
      if (objective !== null && realized !== null && objective > 0) {
        return Math.round(100 * realized / objective) + '%'
      }

      return '-'
    },

    /**
     * Get renewal
     *
     * @param renewal
     * @returns {number|null}
     */
    getRenewal(renewal) {
      if (renewal > 0) {
        return Math.round(renewal * 100) + '%'
      }

      return '-'
    },


    /**
     * Filter the salesmen to display according to options
     * @param drData
     * @returns {*}
     */
    filterDrSalesmen(drData) {
      if (this.hideEmptySalesmenRows) {
        return drData.filter(user => user.objective > 0 || user.turnover > 0 || user.renewal > 0 || user.discount_rate > 0)
      }
      return drData
    },

    calculateDrTotalTurnover(drData) {
      return drData.reduce((total, user) => total + user.turnover, 0)
    },

    calculateDrTotalObjective(drData) {
      return drData.reduce((total, user) => total + user.objective, 0)
    },

    calculateDrMeanRenewalRate(drData) {
      let valid_data = drData.filter((user) => user.renewal != null)
      let total = valid_data.length

      if (total > 0) {
        return valid_data.reduce((total, user) => total + user.renewal, 0) / total
      }
      return null
    },

    calculateDrMeanDiscountRate(drData) {
      let valid_data = drData.filter((user) => user.discount_rate != null)
      let total = valid_data.length
      return valid_data.reduce((total, user) => total + user.discount_rate, 0) / total
    },

    calculateDrMeanConversionRate(drData) {
      let valid_data = drData.filter((user) => user.conversion_rate != null)
      let total = valid_data.length
      return valid_data.reduce((total, user) => total + user.conversion_rate, 0) / total
    },

    calculateDrMeanAverageBasket(drData) {
      let valid_data = drData.filter((user) => user.average_basket != null)
      let total = valid_data.length
      return valid_data.reduce((total, user) => total + user.average_basket, 0) / total
    },

    calculateDrMeanSignatureDelay(drData) {
      let valid_data = drData.filter((user) => user.signature_delay != null)
      let total = valid_data.length

      return valid_data.reduce((total, user) => total + user.signature_delay, 0) / total
    },
  },
}
</script>