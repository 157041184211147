<template>
  <div class="tile indicator number-with-target">
    <div v-if="loading" class="loader">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-else class="content">
      <div class="legend">{{ legend }}</div>
      <div class="values d-flex flex-row align-items-center">
        <div v-if="currency" class="align-self-start currency">{{ currency }}</div>
        <div class="fw-bold text-dark value">{{ shortenValue(value) }} sur {{ shortenValue(target) }}</div>
        <div>({{ formatPercentage(value/target) }})</div>
      </div>
      <div :class="{ 'target-bar': true, 'complete': targetPercentage >= 1 }">
        <div class="value" :style="'width:' + targetBarWidth + '%'"></div>
      </div>
      <bar v-if="history.length > 2"
           id="my-chart-id"
           :options="chartOptions"
           :data="chartData"
      ></bar>
    </div>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs'

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "IndicatorNumberWithTarget",

  components: {
    Bar
  },

  props: {
    value: {
      type: Number,
      default: 0.0,
    },
    target: {
      type: Number,
      default: null,
    },
    history: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: null,
    },
    legend: {
      type: String,
      default: "",
    },
    formatValue: {
      type: Boolean,
      default: true,
    },
    decimals: {
      type: Number,
      default: 2,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      objectiveColor: 'forestgreen',
      realizedNegativeColor: 'firebrick',
      realizedPositiveColor: 'lightgreen',
    }
  },

  computed: {
    /**
     * Return the value evolution as a percentage
     * @returns {null}
     */
    targetPercentage() {
      if (this.target) {
        return this.value / this.target
      }
      return null
    },

    targetBarWidth() {
      // if the target is not achieved, the internal bar is as wide as the achieved percentage
      if (this.targetPercentage <= 1) {
        return this.targetPercentage * 100
      }

      // if the target is achieved, the internal var should is smaller to represent the excess
      return 100 * (this.target / this.value)
    },

    /**
     * Returns the classes for the value evolution badge
     * @returns {string}
     */
    evolutionSpanClasses() {
      if (this.evolutionValue >= 0) {
        return "badge bg-success"
      } else {
        return "badge bg-danger"
      }
    },

    chartData() {
      let serie1 = []
      let serie2 = []
      let serie2Colors = []
      let serie2Labels = []

      this.history.forEach((data) => {
        serie1.push(Math.min(data.values.objective, data.values.realized))
        serie2.push(Math.abs(data.values.objective - data.values.realized))
        serie2Colors.push(data.values.objective > data.values.realized ? this.realizedNegativeColor : this.realizedPositiveColor)
        serie2Labels.push(data.values.objective > data.values.realized ? 'Manque' : 'Dépassement')
      })

      return {
        labels: this.history.map((data) => data.label),
        datasets: [
          {
            data: serie1,
            backgroundColor: '#28a745',
          },
          {
            data: serie2,
            backgroundColor: serie2Colors,
          }
        ]
      }
    },

    chartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          }
        },
        scales: {
          x: {
            stacked: true,
            display: false,
            grid: {
              display: false
            }
          },
          y: {
            stacked: true,
            display: false,
            grid: {
              display: false
            }
          }
        },
        elements: {
          bar: {
            borderRadius: 50,
          }
        },
      }
    },
  },

  methods: {
    shortenValue(value) {
      if (!this.formatValue) {
        return new Intl.NumberFormat('fr-FR', { style: 'decimal', useGrouping: 'auto' }).format(value.toFixed(this.decimals))
      }

      if (value > 1000000) {
        return new Intl.NumberFormat('fr-FR', { style: 'decimal', useGrouping: 'auto' }).format((value / 1000000).toFixed(this.decimals)) + "M"
      }
      if (value > 10000) {
        return new Intl.NumberFormat('fr-FR', { style: 'decimal', useGrouping: 'auto' }).format((value / 1000).toFixed(this.decimals)) + "k"
      }
      return new Intl.NumberFormat('fr-FR', { style: 'decimal', useGrouping: 'auto' }).format(value.toFixed(this.decimals))
    },

    formatPercentage(value) {
      return new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(value)
    }
  },
}
</script>