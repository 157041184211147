import { defineStore } from "pinia"
import Routing from 'fos-router'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    authEmail: null,
    authError: null,
    authLoggedIn: false,
    authUsername: null,
    authIsAdmin: false,
  }),

  actions: {
    resetLogin() {
      this.authEmail = null
      this.authLoggedIn = false
      this.authUsername = null
      this.authIsAdmin = false
    },

    async getLoggedUser() {
      try {
         fetch(Routing.generate('api_common_get_logged_user'))
          .then(response => {
            if (response.status !== 200) {
              this.resetLogin()
              return;
            }
            return response.json()
          })
          .then(data => {
            this.authEmail = data.email
            this.authLoggedIn = true
            this.authUsername = data.username
            this.authIsAdmin = data.is_admin
          })
          .catch(error => {
            this.authError = error
            this.resetLogin()
          })
      } catch (error) {
        this.authError = error
        console.error(error)
      }
    }
  },
})
