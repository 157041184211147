<template>
  <div id="dashboard-property-page">
    <div class="row">
      <div class="col-6">
        <div class="tile">
          <h3>Données par réseau</h3>
          <div>
            <table v-if="indicators.networks" class="table table-striped">
              <thead>
              <tr>
                <th scope="col">Réseau</th>
                <th scope="col" class="text-right">Chiffre d'affaires</th>
                <th scope="col" class="text-right">Taux de remplissage</th>
                <th scope="col" class="text-right">Pourcentage de remise</th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="network in indicators.networks.purchase_orders">
                  <td>{{ network.network }}</td>
                  <td class="text-right">{{ formatCurrency(network.total_turnover) }}</td>
                  <td class="text-right">{{ formatPercentage(networkFillRate(network.network, 0)) }}</td>
                  <td class="text-right">{{ formatPercentage(network.discount_rate) }}</td>
                </tr>
                <tr class="fw-bold">
                  <td>Total</td>
                  <td class="text-right">{{ formatCurrency(calculateTotalTurnovers(indicators.networks.purchase_orders)) }}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="tile mt-3">
          <h3>Données par station</h3>
          <div>
            <div class="form-group">
              <input type="text" class="form-control"
                     v-model="stationSearchString"
                     placeholder="Rechercher des stations"
                     @keyup="debounceSearch"
              >
            </div>
            <table v-if="singleStationIndicators && !loadingStations" class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Station</th>
                  <th scope="col" class="text-right">Chiffre d'affaires</th>
                  <th scope="col" class="text-right">Taux de remplissage</th>
                  <th scope="col" class="text-right">Pourcentage de remise</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="station in singleStationIndicators.purchase_orders">
                  <td>{{ station.name }}</td>
                  <td class="text-right">{{ formatCurrency(station.total_discount_price) }}</td>
                  <td class="text-right">{{ formatPercentage(stationFillRate(station.name, 0)) }}</td>
                  <td class="text-right">{{ formatPercentage(station.discount_rate) }}</td>
                </tr>
                <tr class="fw-bold">
                  <td>Total</td>
                  <td class="text-right">{{ formatCurrency(calculateTotalTurnovers(singleStationIndicators.purchase_orders)) }}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div v-if="loadingStations" class="loader">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="tile">
          <h3>Données par direction régionale</h3>
          <div>
            <table v-if="indicators.regional_directorates" class="table table-striped">
              <thead>
              <tr>
                <th scope="col">Direction Régionale</th>
                <th scope="col" class="text-right">Chiffre d'affaires</th>
                <th scope="col" class="text-right">Taux de remplissage</th>
                <th scope="col" class="text-right">Pourcentage de remise</th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="dr in indicators.regional_directorates.turnover">
                  <td>{{ dr.name ? dr.name : "Aucune DR" }}</td>
                  <td class="text-right">{{ formatCurrency(dr.total_discount_price) }}</td>
                  <td class="text-right">{{ formatPercentage(regionalDirectorateFillRate(dr.name, 0)) }}</td>
                  <td class="text-right">{{ formatPercentage(dr.discount_rate) }}</td>
                </tr>
                <tr class="fw-bold">
                  <td>Total</td>
                  <td class="text-right">{{ formatCurrency(calculateTotalTurnovers(indicators.regional_directorates.turnover)) }}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Routing from 'fos-router'

export default {
  name: "DashboardPageProperty",

  props: {
    startDate: String,
    endDate: String,
    rateCalculationType: String,
  },

  data() {
    return {
      indicators: {},
      singleStationIndicators: null,
      loading: true,
      loadingStations: false,
      stationSearchString: '',
      searchDebounceTimer: null,
    };
  },

  mounted() {
    this.fetchAll()
  },

  watch: {
    startDate() {
      this.fetchAll()
    },
    endDate() {
      this.fetchAll()
    },
    rateCalculationType() {
      this.fetchAll()
    }
  },

  methods: {
    /**
     * Method called to refresh all home indicators
     */
    fetchAll() {
      this.loading = true
      axios.get(Routing.generate('api_dashboard_property', {
        dashboard_start_date: this.startDate,
        dashboard_end_date: this.endDate,
        rate_calculation_type: this.rateCalculationType,
      }))
        .then(response => {
          this.indicators = response.data.results
          this.loading = false
        })
        .catch(error => {
          console.error(error)
        })
    },

    formatCurrency(amount, currency = 'EUR') {
      return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: currency }).format(amount)
    },

    formatPercentage(amount, digits = 2) {
      return new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: digits, maximumFractionDigits: digits }).format(amount)
    },

    calculateTotalTurnovers(networks) {
      return networks.reduce((total, group) => total + group.total_turnover, 0)
    },

    networkFillRate(networkName) {
      const network = this.indicators.networks.fill_rates.find(fillRate => fillRate.network === networkName)
      return network ? network.fill_rate : 0
    },

    regionalDirectorateFillRate(drName) {
      const dr = this.indicators.regional_directorates.fill_rates.find(fillRate => fillRate.name === drName)
      return dr ? dr.fill_rate : 0
    },

    stationFillRate(stationName) {
      const region = this.singleStationIndicators.fill_rates.find(fillRate => fillRate.station === stationName)
      return region ? region.fill_rate : 0
    },

    searchStations() {
      this.loadingStations = true
      this.singleStationIndicators = null

      axios.get(Routing.generate('api_dashboard_property_stations', {
        dashboard_start_date: this.startDate,
        dashboard_end_date: this.endDate,
        search_string: this.stationSearchString,
      }))
        .then(response => {
          this.singleStationIndicators = response.data.stations
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.loadingStations = false
        })
    },

    debounceSearch() {
      if (this.stationSearchString.length > 2) {
        let self = this
        clearTimeout(this.searchDebounceTimer);
        this.searchDebounceTimer = setTimeout(function () {
          self.searchStations()
        }, 500);
      }
    },
  },
}
</script>