<template>
  <div id="dashboard-commercial-analysis-page">
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 indicator-container">
            <indicator-helper indicator="mean_commercial_objective"></indicator-helper>
            <indicator-number-with-target
                v-if="indicators.commercial_objective"
                :value="indicators.commercial_objective.current.realized"
                :target="indicators.commercial_objective.current.objective"
                :history="indicators.commercial_objective.history"
                :loading="loading"
                :format-value="false"
                :decimals="0"
                legend="Objectif"
                chart-color="blue"
                currency="€"
            ></indicator-number-with-target>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 indicator-container">
            <indicator-helper indicator="mean_renewal_rate"></indicator-helper>
            <indicator-number-evolution
                v-if="indicators.renewal_rate"
                :value="100 * indicators.renewal_rate.current"
                :previous="100 * indicators.renewal_rate.previous"
                :history="indicators.renewal_rate.history"
                :loading="loading"
                :format-value="false"
                :calculation-type="rateCalculationType"
                legend="Taux de renouvellement"
                chart-color="lightskyblue"
                currency="%"
            ></indicator-number-evolution>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 indicator-container">
            <indicator-helper indicator="mean_average_basket"></indicator-helper>
            <indicator-number-evolution
                v-if="indicators.average_basket"
                :value="indicators.average_basket.current"
                :previous="indicators.average_basket.previous"
                :history="indicators.average_basket.history"
                :loading="loading"
                legend="Panier moyen signé"
                chart-color="yellow"
                currency="€"
            ></indicator-number-evolution>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 indicator-container">
            <indicator-helper indicator="mean_signature_delay"></indicator-helper>
            <indicator-number-evolution
                v-if="indicators.signature_delay"
                :value="indicators.signature_delay.current"
                :previous="indicators.signature_delay.previous"
                :history="indicators.signature_delay.history"
                :loading="loading"
                :format-value="false"
                legend="Délai de signature"
                chart-color="darkorange"
                currency="JOURS"
            ></indicator-number-evolution>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 indicator-container">
            <indicator-helper indicator="mean_discount_rate"></indicator-helper>
            <indicator-number-evolution
              v-if="indicators.discount_rate"
              :value="indicators.discount_rate.current"
              :previous="indicators.discount_rate.previous"
              :history="indicators.discount_rate.history"
              :loading="loading"
              :format-value="false"
              legend="Taux de remise"
              chart-color="lightskyblue"
              currency="%"
            ></indicator-number-evolution>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 indicator-container">
            <indicator-helper indicator="mean_conversion_rate"></indicator-helper>
            <indicator-number-evolution
                v-if="indicators.conversion_rate"
                :value="indicators.conversion_rate.current"
                :previous="indicators.conversion_rate.previous"
                :history="indicators.conversion_rate.history"
                :loading="loading"
                :format-value="false"
                :calculation-type="rateCalculationType"
                legend="Taux de conversion"
                chart-color="firebrick"
                currency="%"
            ></indicator-number-evolution>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 indicator-container" style="display:none;">
            <indicator-helper indicator="dispute_rate"></indicator-helper>
            <indicator-number-evolution
                v-if="indicators.dispute_rate"
                :value="100 * indicators.dispute_rate.current"
                :previous="100 * indicators.dispute_rate.previous"
                :history="indicators.dispute_rate.history"
                :loading="loading"
                :format-value="false"
                legend="Taux de litige"
                chart-color="lightskyblue"
                currency="%"
            ></indicator-number-evolution>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-12">
            <div class="tile">
              <div class="d-flex flex-row">
                <h3>CA par catégorie de produit</h3>
                <div class="top-right-tool">
                  <div class="tool-content d-flex flex-row">
                    <div>
                      <select class="form-select" v-model="aggregateCategoryTurnoverBy">
                        <option value="">Aucune agrégation</option>
                        <option value="dr">Agréger par direction régionale</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <table v-if="indicatorTurnoverPerProductCategory && aggregateCategoryTurnoverBy == 'dr'" class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Direction régionale</th>
                    <th scope="col">Catégorie</th>
                    <th scope="col" class="text-right">Chiffre d'affaires</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in indicatorTurnoverPerProductCategory">
                    <td 
                      v-if="getDrRowSpanForCategoryAggregatedTurnover(data.regional_directorate_name, index) > 0" 
                      :rowspan="getDrRowSpanForCategoryAggregatedTurnover(data.regional_directorate_name, index)"
                    >{{ data.regional_directorate_name }}</td>
                    <td>{{ data.category }}</td>
                    <td class="text-right">{{ formatCurrency(data.turnover) }}</td>
                  </tr>
                  <tr class="fw-bold">
                    <td colspan="2">Total</td>
                    <td class="text-right">{{ formatCurrency(calculateTotalCA(indicatorTurnoverPerProductCategory)) }}</td>
                  </tr>
                </tbody>
              </table>

              <table v-else-if="indicatorTurnoverPerProductCategory" class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Catégorie</th>
                    <th scope="col" class="text-right">Chiffre d'affaires</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in indicatorTurnoverPerProductCategory">
                    <td>{{ data.category }}</td>
                    <td class="text-right">{{ formatCurrency(data.turnover) }}</td>
                  </tr>
                  <tr class="fw-bold">
                    <td>Total</td>
                    <td class="text-right">{{ formatCurrency(calculateTotalCA(indicatorTurnoverPerProductCategory)) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <div class="tile">
              <div class="d-flex flex-row">
                <h3>CA par durée de diffusion</h3>
                <div class="top-right-tool">
                  <div class="tool-content d-flex flex-row">
                    <div>
                      <select class="form-select" v-model="aggregateDurationTurnoverBy">
                        <option value="">Aucune agrégation</option>
                        <option value="dr">Agréger par direction régionale</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="alert alert-info mt-2">Seuls les produits de diffusion sont pris en compte</div>

              <table v-if="indicatorTurnoverPerProductDuration && aggregateDurationTurnoverBy == 'dr'" class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Direction régionale</th>
                    <th scope="col">Durée de diffusion</th>
                    <th scope="col" class="text-right">Chiffre d'affaires</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in indicatorTurnoverPerProductDuration">
                    <td 
                      v-if="getDrRowSpanForDurationAggregatedTurnover(data.regional_directorate_name, index) > 0" 
                      :rowspan="getDrRowSpanForDurationAggregatedTurnover(data.regional_directorate_name, index)"
                    >{{ data.regional_directorate_name }}</td>
                    <td>{{ data.duration }}</td>
                    <td class="text-right">{{ formatCurrency(data.turnover) }}</td>
                  </tr>
                  <tr class="fw-bold">
                    <td colspan="2">Total</td>
                    <td class="text-right">{{ formatCurrency(calculateTotalCA(indicatorTurnoverPerProductDuration)) }}</td>
                  </tr>
                </tbody>
              </table>

              <table v-else-if="indicatorTurnoverPerProductDuration" class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Durée de diffusion</th>
                    <th scope="col" class="text-right">Chiffre d'affaires</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in indicatorTurnoverPerProductDuration">
                    <td>{{ data.duration }}</td>
                    <td class="text-right">{{ formatCurrency(data.turnover) }}</td>
                  </tr>
                  <tr class="fw-bold">
                    <td>Total</td>
                    <td class="text-right">{{ formatCurrency(calculateTotalCA(indicatorTurnoverPerProductDuration)) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IndicatorHelper from "./IndicatorHelper.vue";
import IndicatorNumberEvolution from "./IndicatorNumberEvolution.vue"
import IndicatorNumberWithTarget from "./IndicatorNumberWithTarget.vue"
import Routing from 'fos-router'

export default {
  name: "DashboardPageCommercialAnalysis",

  components: {
    IndicatorHelper,
    IndicatorNumberWithTarget,
    IndicatorNumberEvolution,
  },

  data() {
    return {
      aggregateCategoryTurnoverBy: "",
      aggregateDurationTurnoverBy: "",
      indicators: {},
      indicatorTurnoverPerProductCategory: null,
      indicatorTurnoverPerProductDuration: null,
      loading: true,
      lastRenderedDr: null,
    }
  },

  props: {
    startDate: String,
    endDate: String,
    rateCalculationType: String,
  },

  mounted() {
    this.fetchAll()
  },

  watch: {
    startDate() {
      this.fetchAll()
    },
    endDate() {
      this.fetchAll()
    },
    rateCalculationType() {
      this.fetchAll()
    },
    aggregateDurationTurnoverBy() {
      this.refreshTurnoverByProductDurationTable()
    },
    aggregateCategoryTurnoverBy() {
      this.refreshTurnoverByProductCategoryTable()
    },
  },

  methods: {
    /**
     * Method called to refresh all indicators
     */
    fetchAll() {
      this.loading = true

      axios.get(Routing.generate('api_dashboard_commercial_analysis', {
        dashboard_start_date: this.startDate,
        dashboard_end_date: this.endDate,
        rate_calculation_type: this.rateCalculationType,
      }))
          .then(response => {
            this.indicators = response.data.results
            this.indicatorTurnoverPerProductDuration = response.data.results.turnover.per_product_duration
            this.indicatorTurnoverPerProductCategory = response.data.results.turnover.per_product_category
            this.loading = false
          })
          .catch(error => {
            console.error(error)
          })
    },

    /**
     * Re-load the turnover by product duration table when the aggregation select changes
     */
    refreshTurnoverByProductDurationTable() {
      this.indicatorTurnoverPerProductDuration = null

      axios.get(Routing.generate('api_dashboard_turnover_by_product_duration', {
        dashboard_start_date: this.startDate,
        dashboard_end_date: this.endDate,
        rate_calculation_type: this.rateCalculationType,
        aggregate_by: this.aggregateDurationTurnoverBy,
      }))
          .then(response => {
            this.indicatorTurnoverPerProductDuration = response.data.results.turnover.per_product_duration
            this.loading = false
          })
          .catch(error => {
            console.error(error)
          })
    },

    /**
     * Re-load the turnover by product category table when the aggregation select changes
     */
    refreshTurnoverByProductCategoryTable() {
      this.indicatorTurnoverPerProductCategory = null

      axios.get(Routing.generate('api_dashboard_turnover_by_product_category', {
        dashboard_start_date: this.startDate,
        dashboard_end_date: this.endDate,
        rate_calculation_type: this.rateCalculationType,
        aggregate_by: this.aggregateCategoryTurnoverBy,
      }))
          .then(response => {
            this.indicatorTurnoverPerProductCategory = response.data.results.turnover.per_product_category
            this.loading = false
          })
          .catch(error => {
            console.error(error)
          })
    },

    formatCurrency(amount, currency = 'EUR') {
      return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: currency, useGrouping: 'auto' }).format(amount)
    },

    formatPercentage(amount, digits = 2) {
      return new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: digits }).format(amount)
    },

    calculateTotalCA(data) {
      return data.reduce((total, item) => total + item.turnover, 0)
    },

    getDrRowSpanForDurationAggregatedTurnover(drName, index) {
      // After the row 1, we check if the row DR is the same as the previous row. If true, the rowspan is 0 (the row DR name won't be displayed)
      if (index > 0 && this.indicatorTurnoverPerProductDuration[index].regional_directorate_name == this.indicatorTurnoverPerProductDuration[index - 1].regional_directorate_name) {
        return 0
      }

      // For row 0 and for new DR row, we just count the number of items for the given DR in the results table
      if (this.indicatorTurnoverPerProductDuration && this.aggregateDurationTurnoverBy == 'dr') {
        return this.indicatorTurnoverPerProductDuration.filter((row) => row.regional_directorate_name == drName).length
      }

      return 0
    },

    getDrRowSpanForCategoryAggregatedTurnover(drName, index) {
      // After the row 1, we check if the row DR is the same as the previous row. If true, the rowspan is 0 (the row DR name won't be displayed)
      if (index > 0 && this.indicatorTurnoverPerProductCategory[index].regional_directorate_name == this.indicatorTurnoverPerProductCategory[index - 1].regional_directorate_name) {
        return 0
      }

      // For row 0 and for new DR row, we just count the number of items for the given DR in the results table
      if (this.indicatorTurnoverPerProductCategory && this.aggregateCategoryTurnoverBy == 'dr') {
        return this.indicatorTurnoverPerProductCategory.filter((row) => row.regional_directorate_name == drName).length
      }

      return 0
    }
  },
}
</script>