<template>
  <div class="indicator-helper"
       :title="helps[indicator]"
       data-bs-toggle="tooltip"
       data-bs-placement="bottom"
  >
    <i class="fa-solid fa-circle-question"></i>
  </div>
</template>

<script>
export default {
  name: "IndicatorHelper",

  props: {
    indicator: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      helps: {
        global_turnover: "Somme des montants nets remisés de tous les produits de tous les bons de commande de la période sélectionnée",
        global_turnover_no_technical_charges: "CA auquel sont retranchés tous les produits de type frais techniques",
        new_clients: "Nombre de clients ayant signé leur premier bon de commande sur la période sélectionnée",
        new_prospects: "Nombre de prospects ayant reçu leur premier bon de commande sur la période sélectionnée",
        mean_fill_rate: "Taux de remplissage moyen de toutes les stations sur la période sélectionnée",
        mean_commercial_objective: "Remplissage global des objectifs commerciaux sur la période sélectionnée",
        mean_renewal_rate: "Taux de renouvellement moyen de tous les clients sur la période sélectionnée",
        mean_average_basket: "Montant moyen de tous les bons de commande signés sur la période sélectionnée",
        mean_discount_rate: "Taux de remise moyen de tous les bons de commande signés sur la période sélectionnée. Le taux de remise est calculé via la formule (net catalogue - net remisé) / net catalogue.",
        mean_signature_delay: "Délai de signature moyen de tous les bons de commande signés sur la période sélectionnée. Le délai est calculé via la formule (date de signature - date de réception).",
        mean_conversion_rate: "Taux de conversion moyen sur la période sélectionnée. Le taux de conversion est le calculé via les montants des BDC signés, divisé par les montants des BDC envoyés.",
        local_national: "Le CA national est calculé en sommant les CA des DR indiquées comme nationales dans les paramètres applicatifs (voir admin)",
        dispute_rate: "Taux de litige en cours, indépendant de la période sélectionnée. Montant total en litige, divisé par le montant des BDC de la période des litiges.",
      }
    }
  }
}
</script>