<template>
  <div id="dashboard-menu">
    <div class="row">
      <div class="col-3 col-xl-2">
        <label for="start-date">Depuis le</label>
        <select v-model="dateChoiceType" class="form-control">
          <option value="week">Semaine dernière</option>
          <option value="month">Mois en cours</option>
          <option value="year">Année en cours</option>
          <option value="custom">Personnalisé</option>
        </select>
      </div>
      <div class="col-3 col-xl-2">
        <div v-if="dateChoiceType === 'week'" class="date-choice-type">
          <div class="btn btn-warning" @click="changeWeek(-1)">
            <i class="fas fa-angle-left"></i>
          </div>
          <div class="unit-title">
            Semaine {{ weekNumber }}
          </div>
          <div class="btn btn-warning" @click="changeWeek(1)">
            <i class="fas fa-angle-right"></i>
          </div>
        </div>

        <div v-if="dateChoiceType === 'month'"  class="date-choice-type">
          <div class="btn btn-warning" @click="changeMonth(-1)">
            <i class="fas fa-angle-left"></i>
          </div>
          <div class="unit-title">
            {{ monthName }}
          </div>
          <div class="btn btn-warning" @click="changeMonth(1)">
            <i class="fas fa-angle-right"></i>
          </div>
        </div>

        <div v-if="dateChoiceType === 'year'" class="date-choice-type">
          <div class="btn btn-warning" @click="changeYear(-1)">
            <i class="fas fa-angle-left"></i>
          </div>
          <div class="unit-title">
            {{ yearNumber }}
          </div>
          <div class="btn btn-warning" @click="changeYear(1)">
            <i class="fas fa-angle-right"></i>
          </div>
        </div>
      </div>
      <div class="col-3 col-xl-2">
        <label for="start-date">Depuis le</label>
        <input id="start-date" type="date" class="form-control" v-model="startDate" :disabled="dateChoiceType !== 'custom'"/>
      </div>
      <div class="col-3 col-xl-2">
        <label for="end-date">Jusqu'au</label>
        <input id="end-date" type="date" class="form-control" v-model="endDate" :disabled="dateChoiceType !== 'custom'"/>
      </div>

      <div class="col-3 col-xl-2">
        <label for="rate-calculation-type">Calculer les taux</label>
        <select id="rate-calculation-type" class="form-control" v-model="rateCalculationType">
          <option value="number">En nombre de BDC</option>
          <option value="turnover">En prix total</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import Routing from 'fos-router'

export default {
  name: "DashboardParamMenu",

  data() {
    return {
      dateChoiceType: 'custom',
      endDate: null,
      rateCalculationType: 'number',
      startDate: null,
    }
  },

  mounted() {
    this.retrieveDateRange()
  },

  watch: {
    dateChoiceType() {
      if (this.dateChoiceType === 'week') {
        this.useLastWeekDates()
      } else if (this.dateChoiceType === 'month') {
        this.useCurrentMonthDates()
      } else if (this.dateChoiceType === 'year') {
        this.useYearDates()
      } else if (this.dateChoiceType === 'custom') {
        this.useCustomDates()
      }
    },
    startDate() {
      this.handleParamsUpdate()
    },
    endDate() {
      this.handleParamsUpdate()
    },
    rateCalculationType() {
      this.handleParamsUpdate()
    }
  },

  computed: {
    weekNumber() {
      return moment(this.startDate).week()
    },
    monthName() {
      return moment(this.startDate).format('MMMM YYYY')
    },
    yearNumber() {
      return moment(this.startDate).year()
    }
  },

  methods: {
    /**
     * Save the new dates in session and emit an event to update the dashboard
     */
    handleParamsUpdate() {
      this.$emit('params-updated', {
        startDate: this.startDate,
        endDate: this.endDate,
        rateCalculationType: this.rateCalculationType,
      })
    },

    /**
     * Call the API to get the initial date range to set on the dashboard inputs
     */
    retrieveDateRange() {
      let self = this
      axios.get(Routing.generate('api_common_get_dashboard_date_range'))
        .then(function (response) {
          self.startDate = moment(response.data.start, 'YYYYMMDD').format('YYYY-MM-DD')
          self.endDate = moment(response.data.end, 'YYYYMMDD').format('YYYY-MM-DD')
          self.handleParamsUpdate()
        })
        .catch(function (error) {
          console.error(error);
        });
    },

    /**
     * Set the current dates on current week dates
     */
    useLastWeekDates() {
      let currentDate = moment()
      this.startDate = currentDate.clone().startOf('week').subtract(1, 'weeks').format('YYYY-MM-DD')
      this.endDate = currentDate.clone().endOf('week').subtract(1, 'weeks').format('YYYY-MM-DD')
    },

    /**
     * Set the current dates on current month dates
     */
    useCurrentMonthDates() {
      let currentDate = moment()
      this.startDate = currentDate.clone().startOf('month').format('YYYY-MM-DD')
      this.endDate = currentDate.clone().endOf('month').format('YYYY-MM-DD')
    },

    useYearDates(year) {
      if (year === undefined) {
        year = moment().year()
      }
      let currentDate = moment({ year: year, month: 1, day: 1 })
      this.startDate = currentDate.clone().startOf('year').format('YYYY-MM-DD')
      this.endDate = moment.min(
          currentDate.clone().endOf('year'),
          moment()
      ).format('YYYY-MM-DD')
    },


    useCustomDates() {
      this.dateChoiceType = 'custom'
    },

    changeWeek(weekToAdd) {
      this.startDate = moment(this.startDate).add(weekToAdd, 'weeks').format('YYYY-MM-DD')
      this.endDate = moment(this.endDate).add(weekToAdd, 'weeks').format('YYYY-MM-DD')
    },

    changeMonth(monthToAdd) {
      this.startDate = moment(this.startDate).add(monthToAdd, 'months').format('YYYY-MM-DD')
      this.endDate = moment(this.endDate).add(monthToAdd, 'months').endOf('month').format('YYYY-MM-DD')
    },

    changeYear(yearToAdd) {
      let currentYear = parseInt(this.startDate.split('-')[0])
      this.useYearDates(currentYear + yearToAdd)
    }
  },
}
</script>