<template>
  <div id="dashboard">
    <dashboard-navbar></dashboard-navbar>
    <div id="dashboard-content">
      <dashboard-param-menu @params-updated="updateParams"></dashboard-param-menu>
      <div id="dashboard-page">
          <router-view :start-date="startDate" :end-date="endDate" :rate-calculation-type="rateCalculationType"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardParamMenu from "./DashboardParamMenu.vue"
import DashboardNavbar from "./DashboardNavbar.vue"

export default {
  name: "Dashboard",

  data() {
    return {
      startDate: null,
      endDate: null,
      rateCalculationType: 'number',
    }
  },

  components: {
    DashboardParamMenu,
    DashboardNavbar,
  },

  methods: {
    /**
     * Save the new dates in session and emit an event to update the dashboard
     */
    updateParams(params) {
      this.startDate = params.startDate
      this.endDate = params.endDate
      this.rateCalculationType = params.rateCalculationType
    },
  }
}
</script>