<template>
  <nav class="navbar navbar-expand-lg navbar-dark">
    <router-link to="/dashboard">
      <img class="navbar-logo" src="/build/images/logo-fillup-light.png">
    </router-link>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <router-link to="/dashboard" class="nav-link">
            <i class="fa-solid fa-house"></i>
              Accueil
            </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/dashboard/patrimoine" class="nav-link">
            <i class="fa-solid fa-gas-pump"></i>
            Patrimoine
          </router-link>
        </li>
        <li class="nav-item active">
          <router-link to="/dashboard/direction-commerciale" class="nav-link">
            <i class="fa-solid fa-user-tie"></i>
            Direction Commerciale
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/dashboard/analyse-commerciale" class="nav-link">
            <i class="fa-solid fa-chart-simple"></i>
            Analyse Commerciale
          </router-link>
        </li>
      </ul>
    </div>

    <div v-if="authLoggedIn">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ authUsername }}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a v-if="authIsAdmin" class="nav-link" :href="adminPath" target="_blank">
              <i class="fa-solid fa-gears"></i>
              Administration
            </a>
            <div v-if="authIsAdmin" class="dropdown-divider"></div>
            <a class="dropdown-item" :href="logoutPath">Déconnexion</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapState } from "pinia"
import { useAuthStore } from "../stores/AuthStore"
import Routing from 'fos-router'

export default {
  name: "DashboardNavbar",

  data() {
    return {};
  },

  computed: {
    // importing information from auth store
    ...mapState(useAuthStore, ['authLoggedIn', 'authUsername', 'authIsAdmin']),

    logoutPath() {
      return Routing.generate('app_logout')
    },

    adminPath() {
      return Routing.generate('admin')
    }
  },

  methods: {},
}
</script>