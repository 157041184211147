<template>
  <div id="dashboard-homepage">
    <div class="row align-items-start">
      <div class="col-6">
        <div class="row">
          <div class="indicator-container col-6">
            <indicator-helper indicator="global_turnover"></indicator-helper>
            <indicator-number-evolution
                v-if="indicators.turnover"
                :value="indicators.turnover.current"
                :previous="indicators.turnover.previous"
                :history="indicators.turnover.history"
                :loading="loading"
                legend="Chiffre d'affaires"
                chart-color="lightskyblue"
                currency="€"
            ></indicator-number-evolution>
          </div>
          <div class="indicator-container col-6">
            <indicator-helper indicator="new_clients"></indicator-helper>
            <indicator-number-evolution
                v-if="indicators.new_clients"
                :value="indicators.new_clients.current"
                :previous="indicators.new_clients.previous"
                :history="indicators.new_clients.history"
                :format-value="false"
                :decimals="0"
                :loading="loading"
                legend="Nouveaux clients"
                chart-color="lightgreen"
                currency=""
            ></indicator-number-evolution>
          </div>
          <div class="indicator-container col-6">
            <indicator-helper indicator="mean_fill_rate"></indicator-helper>
            <indicator-number-evolution
                v-if="indicators.fill_rate"
                :value="indicators.fill_rate.current"
                :previous="indicators.fill_rate.previous"
                :history="indicators.fill_rate.history"
                :loading="loading"
                legend="Taux de remplissage moyen"
                chart-color="yellow"
                currency="%"
            ></indicator-number-evolution>
          </div>
          <div class="indicator-container col-6">
            <indicator-helper indicator="global_turnover_no_technical_charges"></indicator-helper>
            <indicator-number-evolution
                v-if="indicators.turnover"
                :value="indicators.turnover.no_technical_charges.current"
                :previous="indicators.turnover.no_technical_charges.previous"
                :loading="loading"
                legend="Chiffre d'affaires hors FT"
                chart-color="lightskyblue"
                currency="€"
            ></indicator-number-evolution>
          </div>
          <div class="indicator-container col-6">
            <indicator-helper indicator="local_national"></indicator-helper>
            <indicator-number-distribution
                v-if="indicators.turnover"
                :labels="['National', 'Local']"
                :values="[indicators.turnover.national_local.current.national, indicators.turnover.national_local.current.local]"
                :previous="[indicators.turnover.national_local.previous.national, indicators.turnover.national_local.previous.local]"
                :colors="['#007bff', '#28a745']"
                :format-value="false"
                :decimals="0"
                :loading="loading"
                legend="Local / National"
                chart-color="lightgreen"
                currency="€"
            ></indicator-number-distribution>
          </div>
        </div>
      </div>
      <div class="col-6">
        <map-stations :start-date="startDate" :end-date="endDate"></map-stations>
      </div>
    </div>
  </div>
</template>

<script>
import IndicatorHelper from "./IndicatorHelper.vue"
import IndicatorNumberDistribution from "./IndicatorNumberDistribution.vue"
import IndicatorNumberEvolution from "./IndicatorNumberEvolution.vue"
import MapStations from "./MapStations.vue"
import Routing from 'fos-router'

export default {
  name: "DashboardPageHome",

  components: {
    IndicatorHelper,
    IndicatorNumberDistribution,
    IndicatorNumberEvolution,
    MapStations,
  },

  props: {
    startDate: String,
    endDate: String,
    rateCalculationType: String,
  },

  data() {
    return {
      indicators: {},
      loading: true,
    }
  },

  mounted() {
    this.fetchAll()
  },

  watch: {
    startDate() {
      this.fetchAll()
    },
    endDate() {
      this.fetchAll()
    },
    rateCalculationType() {
      this.fetchAll()
    }
  },

  methods: {
    /**
     * Method called to refresh all home indicators
     */
    fetchAll() {
      this.loading = true
      axios.get(Routing.generate('api_dashboard_home', {
        dashboard_start_date: this.startDate,
        dashboard_end_date: this.endDate,
        rate_calculation_type: this.rateCalculationType,
      }))
        .then(response => {
          this.indicators = response.data.results
          this.parameters = response.data.parameters
          this.loading = false
        })
        .catch(error => {
          console.error(error)
        })
    },
  },
}
</script>