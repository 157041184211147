<template>
  <div class="tile map-container map-stations">
    <div class="map-menu">
    </div>
    <div style="height:800px; width:100%">
      <l-map
        ref="map-stations"
        v-model:zoom="zoom" :center="[47.41322, 1.50000]"
        :options="{ preferCanvas: true }"
      >
        <l-tile-layer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            layer-type="base"
            name="OpenStreetMap"
        ></l-tile-layer>
        <l-circle-marker
            v-if="stationsGeoInfos"
            v-for="station in stationsGeoInfos"
            @click="displayPopup($event.target, station.name, station.popup_info)"
            :lat-lng="[station.latitude, station.longitude]"
            :radius="station.radius"
            :color="station.color"
            :weight="station.weight"
            :opacity="station.opacity"
        />
      </l-map>
    </div>
  </div>
</template>

<script>
import Routing from 'fos-router'
import { LMap, LTileLayer, LCircleMarker, LPopup } from "@vue-leaflet/vue-leaflet"

export default {
  name: "MapStations",

  components: {
    LCircleMarker,
    LMap,
    LTileLayer,
    LPopup
  },

  props: {
    startDate: String,
    endDate: String,
  },

  data() {
    return {
      mapData: "fill_rate", // not used for now. 
      stationsGeoInfos: null,
      zoom: 6,
    };
  },

  mounted() {
    this.loadBasicStations()
  },

  watch: {
    startDate() {
      this.loadBasicStations()
    },
    endDate() {
      this.loadBasicStations()
    }
  },

  methods: {
    loadBasicStations() {
      axios.get(Routing.generate('api_map_get_stations'))
        .then(response => {
          this.stationsGeoInfos = response.data.stations
        })
        .catch(error => {
          console.error(error)
        })
    },

    displayPopup(marker, title, informationValue = {}) {
      let popup_string = "<p><strong>" + title + "</strong></p>"

      for (const [key, value] of Object.entries(informationValue)) {
        popup_string += "<p>" + key + " : " + value + "</p>"
      }

      marker.bindPopup(popup_string)
    }
  }
}
</script>
