// Loading stylesheets
import "./styles/app.scss"

// Loading JS libs
const $ = require('jquery');
global.$ = global.jQuery = $;
global.L = require('leaflet')
global.Plotly = require('plotly.js-dist')
global.axios = require('axios')
global.moment = require('moment')

require('jquery')
require('bootstrap')

global.moment.locale('fr')

// Loading local JS files
require('./js/dashboard.js')

// Initializing the bootstrap widgets
$(document).ready(function () {
    $('.dropdown-toggle').dropdown()
    $('[data-bs-toggle="tooltip"]').tooltip()
})
