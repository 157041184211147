<template>
  <div class="tile indicator number-distribution">
    <div v-if="loading" class="loader">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="content">
      <div class="legend">{{ legend }}</div>
      <div class="d-flex charts">
        <div class="current-chart">
          <div>
            Période N
            <pie
              id="current-chart-id"
              :options="chartOptions"
              :data="currentChartData"
            ></pie>
          </div>
        </div>
        <div class="previous-chart">
          <div>
            N-1
            <pie
              id="previous-chart-id"
              :options="chartOptions"
              :data="previousChartData"
            ></pie>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs'

import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale, ArcElement } from 'chart.js'
import $ from "jquery";
ChartJS.register(Title, Tooltip, Legend, CategoryScale, LinearScale, ArcElement)

export default {
  name: "IndicatorNumberEvolution",

  components: {
    Pie
  },

  props: {
    values: {
      type: Array,
      default: () => [],
    },
    previous: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: null,
    },
    legend: {
      type: String,
      default: "",
    },
    chartColor: {
      type: String,
      default: "#007bff",
    },
    formatValue: {
      type: Boolean,
      default: true,
    },
    decimals: {
      type: Number,
      default: 2,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    $('[data-bs-toggle="tooltip"]').tooltip()
  },

  computed: {
    currentChartData() {
      return {
        labels: this.labels,
        datasets: [ {
          data: this.values,
          backgroundColor: this.colors,
        } ]
      }
    },

    previousChartData() {
      return {
        labels: this.labels,
        datasets: [ {
          data: this.previous,
          backgroundColor: this.colors,
        } ]
      }
    },

    chartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          }
        },
        scales: {
          x: {
            display: false,
            grid: {
              display: false
            }
          },
          y: {
            display: false,
            grid: {
              display: false
            }
          }
        },
        elements: {
          bar: {
            backgroundColor: this.chartColor,
            borderRadius: 50,
          }
        },
      }
    },
  },

  methods: {
    shortenValue(value) {
      if (!this.formatValue) {
        if (this.decimals) {
          return new Intl.NumberFormat('fr-FR', { style: 'decimal', useGrouping: 'auto' }).format(value.toFixed(this.decimals))
        }
        return value
      }

      if (value > 1000000) {
        return new Intl.NumberFormat('fr-FR', { style: 'decimal', useGrouping: 'auto' }).format((value / 1000000).toFixed(this.decimals)) + "M"
      }
      if (value > 10000) {
        return new Intl.NumberFormat('fr-FR', { style: 'decimal', useGrouping: 'auto' }).format((value / 1000).toFixed(this.decimals)) + "k"
      }
      return new Intl.NumberFormat('fr-FR', { style: 'decimal', useGrouping: 'auto' }).format(value.toFixed(this.decimals))
    }
  },
}
</script>